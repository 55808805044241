// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

// export const APISIGO = 'http://190.124.28.28:8080/api-sigo-1/api';
// export const APISIGOV2 = 'http://190.124.28.28:8080/api-sigo-1/v2/api'
export const APISIGO = 'https://sigoqa.airtek.com.ve/api-sigo-1/api';
export const APISIGOV2 = 'https://sigoqa.airtek.com.ve/api-sigo-1/v2/api';
// export const APISIGO = 'https://sigo.airtek.com.ve/api-sigo-1/api';   //Producción
// export const APISIGOV2 = 'https://sigo.airtek.com.ve/api-sigo-1/v2/api';   //Producción



export const idNube = 1;




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
