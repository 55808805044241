import { Component, ElementRef, ViewChild, Renderer2, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GestionCuadrillaService } from '../../../../services/operaciones/averias-menores/gestion-cuadrilla.service';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

//Modulo de Formularios
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

//Window Bootstrap
declare var window: any;

@Component({
  selector: 'app-gestion-cuadrilla',
  templateUrl: './gestion-cuadrilla.component.html',
  styleUrls: ['./gestion-cuadrilla.component.css'],
})
export class GestionCuadrillaComponent {

  myControl = new FormControl();
  searchControl  = new FormControl();

  dataSource:any;
  dataSourceE:any;

  searchFn(){}

  @ViewChild('buscar') valueBuscar!: ElementRef<HTMLSelectElement>;
  @ViewChild('mdt') valueMdt!: ElementRef<HTMLSelectElement>;

  @ViewChild('buscar2') valueBuscar2!: ElementRef<HTMLSelectElement>;
  @ViewChild('mdt2') valueMdt2!: ElementRef<HTMLSelectElement>;

  @ViewChild('Nodo') valueNodo!: ElementRef<HTMLSelectElement>;
  @ViewChild('nombre') valueNombre!: ElementRef<HTMLSelectElement>;

  @ViewChild('Nodo1') nodo1!: ElementRef<HTMLSelectElement>;


  // @ViewChild(MatPaginator) paginator!: MatPaginator;

  // @ViewChild(MatSort) sort!: MatSort;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();


  constructor(
    private TecService: GestionCuadrillaService,
    private fb: FormBuilder,
    private token: TokenStorageService,
    private toast: ToastrService,
    protected spinner: NgxSpinnerService,
    protected renderer: Renderer2,
    private _liveAnnouncer: LiveAnnouncer,
  ) {}

  //Variables Modals
  AddCuadrilla: any;
  AddTecnico: any;
  EditCuadrilla: any;
  eliminar: any;
  content: any;
  addTecnicos: any = [];
  nodos: any[] = [];
  listOlt: any = {};
  listMDT: any = [];
  nodosCercano: any = {};
  // Lista para los drag
  drag: any;
  listaDeTecnicos: any = [];
  listaDeCuadrillas: any = [];
  listaDeCuadrillasTemp: any = [];
  registro: any;
  editarModal: any = {};
  NodoPreCargado: any = '';
  dataCuadrilla: any;
  RecargarLista: any;
  valor: any;
  // Editar
  ValoresFormEditar: any = {};
  Enviar_Editar: any;
  linkTecnicos: any = '';
  me: any = {};

  done: any = [];

  drag1: any;
  listaTemporal: any = [];

  listLoteMdt: any = [];
  listMDTold: any = [];

  items: number = 10;

  selectedItems: any[] = [];
  selectedValues = new FormControl();

    // Funcion listar Tecnicos

  // ----------------------------------------------------------
  // Boton de Buscar
  // Buscar al Precionar Enter
  // KeypressEnter(nombreTecnico: any): any {
  //   // this.CargarAsignadas()

  //   if (nombreTecnico == '') {
  //     this.listaDeTecnicos = this.listaTemporal;
  //   } else {
  //     this.BuscarConNombre(this.listaTemporal, nombreTecnico);
  //   }
  // }

  onKeyUpEvent(filter: any) {
    if (filter.target.value == '') {
      this.listaDeTecnicos = this.listaTemporal;
    } else {
      this.BuscarConNombre(this.listaTemporal, filter.target.value);
    }
  }


  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  // Opcion para Buscar en Array
  BuscarConNombre(obj: any, parm: string): any {
    this.listaDeTecnicos = [];
    // Filtrar el Array y buscar por el Parametro convirtiendo en Mayuscua
    // El Nombre y parametro a Buscar
    let busqueda: any = obj.filter((element: any) =>
      element.nombre_tecnico
        .toUpperCase()
        .includes(parm.toString().toUpperCase())
    );

    // Agregar al Array que se va a mostrar
    busqueda.forEach((element: any) => {
      this.listaDeTecnicos.push(element);
    });
  }


  onKeyUpEvent2(filter: any) {
    if (filter.target.value == '') {
      this.listaDeCuadrillas = this.listaDeCuadrillasTemp;
    } else {
      this.BuscarConNombreCuadrilla(this.listaDeCuadrillasTemp, filter.target.value);
    }
  }

  BuscarConNombreCuadrilla(obj: any, parm: string): any {
    this.listaDeCuadrillas = [];
    // Filtrar el Array y buscar por el Parametro convirtiendo en Mayuscua
    // El Nombre y parametro a Buscar
    let busqueda: any = obj.filter((element: any) =>
      element.descripcion
        .toUpperCase()
        .includes(parm.toString().toUpperCase())
    );

    // Agregar al Array que se va a mostrar
    busqueda.forEach((element: any) => {
      this.listaDeCuadrillas.push(element);
    });
  }

  ngOnInit(): void {

    this.me = this.token.GetMe();

    this.me.menu.forEach((me: any) => {

      if (me.idMenu == 27) {
        this.linkTecnicos = me.route;

      }
    });

    this.listarTecnicos();

    this.ObtenerNodos();
    this.listarCuadrillas();

    //Modals

    //Añadir Cuadrilla
    this.AddCuadrilla = new window.bootstrap.Modal(
      document.getElementById('AddCuadrilla')
    );

    //Añadir Tecnico
    this.EditCuadrilla = new window.bootstrap.Modal(
      document.getElementById('EditCuadrilla')
    );

    //Eliminar
    this.eliminar = new window.bootstrap.Modal(
      document.getElementById('eliminar')
    );
    //GianBreak
    // this.spinner.hide();



    // this.dropdownList = [
    //   { item_id: 1, item_text: 'Mumbai' },
    //   { item_id: 2, item_text: 'Bangaluru' },
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' },
    //   { item_id: 5, item_text: 'New Delhi' }
    // ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    // this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: 'item_id',
    //   textField: 'item_text',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: true
    // };

  }
  // onItemSelect(item: any) {
  //   console.log(item);
  // }
  // onSelectAll(items: any) {
  //   console.log(items);
  // }

  ngAfterViewInit(): void {
    //Ya no se elimina al cambiar Nodo Gian 21/03/2023
    // this.renderer.listen(this.nodo1.nativeElement, 'change', (event) => {
    //   this.listMdtEdt.forEach((element: any) => {
    //     this.rel_eliminar.push(element);
    //   });
    //   this.listMdtEdt = [];
    // });
  }

  // Listar los Tecnicos
  listarTecnicos() {
    this.TecService.getListTecnicosSinRela().subscribe({
      next: (data) => {
        this.listaDeTecnicos = [];
        this.listaTemporal = [];
        this.content = data;

        //
        for (let index in this.content) {
          // Si codigo de Tecnico es igual a 1 o 2  y si no tiene cuadrilla

          // y no tiene codigo de proveedor Agregar al Array
          // if (this.content[index].tipo_tecnico == '1' || this.content[index].tipo_tecnico == '2' &&
          //    this.content[index].nombre_cuadrilla == "" &&  this.content[index].codigo_proveedor == "")
          // {

          this.drag = {
            nombre_tecnico: this.content[index].nombre_tecnico,
            id_tecnico: this.content[index].id_tecnico,
            tipo_tecnico: this.content[index].tipo_tecnico,
            codigo_cuadrilla: '',
          };
          // Para mostrar en el Arreglo
          this.listaDeTecnicos.push(this.drag);

          // Para usar mas Tarde en el boton de buscar
          this.listaTemporal.push(this.drag);

          // }
        }
      },

      error: (err) => {
        // En caso de error mostrar el error
        this.content = JSON.parse(err.error).message;

        //
      },
    });
    // this.spinner.hide();
  }

  // Listar Cuadrillas
  listarCuadrillas() {
    // this.listaDeCuadrillas = []
    this.TecService.getAllcuadrillas().subscribe({
      next: (data) => {
        this.listaDeCuadrillas = data;
        this.listaDeCuadrillasTemp = data;

        let index = 0;
        this.listaDeCuadrillas.forEach((element: any) => {
          // Convertir en JSON y Comparar String
          // if(JSON.stringify(element) == JSON.stringify(this.listaDeCuadrillas[index]) == true){
          //
          // Si ya Existe no la Agrega
          // }
          // else{
          // Si no Existe lo Agrega
          // this.listaDeCuadrillas.push(element);
          // For para Agregar el ID de la cuadrilla al Arreglo
          for (let f in this.listaDeCuadrillas[index].tecnicosResponse) {
            this.listaDeCuadrillas[index].tecnicosResponse[f].idCcuadrilla =
              this.listaDeCuadrillas[index].id_cuadrilla;
          }
          // }

          // Mapear el index
          index = index + 1;
        });


        //
      },

      error: (err) => {
        // En caso de error mostrar el error
        this.content = JSON.parse(err.error).message;
        //
      },
    });
  }

  // Obtener los Nodos para el Botono de Asignar
  ObtenerNodos(): void {
    this.TecService.getListnodos().subscribe({
      next: (data) => {
        this.nodos = data;
      },
      error: (err) => {
        // En caso de error mostrar el error
        this.nodos = JSON.parse(err.error).message;
      },
    });
  }

  // Filatrar los nodos
  ObtenerNodosCercano(param1: any): any {
    //
    this.TecService.getListnodos().subscribe({
      next: (reponse_ndos) => {
        this.nodosCercano = reponse_ndos;

        for (let x in this.nodosCercano) {
          if (this.nodosCercano[x].codigo_nodo == param1.toString()) {
            this.nodosCercano.splice(x, 1);
            // this.editarModal.nodo_cercano = ""
          }
        }

        // this.nodosCercano = this.content;

      },
      error: (err) => {
        // En caso de error mostrar el error
        this.nodosCercano = JSON.parse(err.error).message;
      },
    });
  }
  //Eventos Modals

  @ViewChild('mdt') matRef1!: MatSelect;
  // Modal de la Cuadrilla Cuadrilla
  openAddCuadrilla() {
    this.indmdt == '';
    this.FormAddCuadrilla.setValue({
      nodo: '',
      n_cuadrilla: ''
  });
    this.FormAddCuadrilla.markAsUntouched();
    this.matRef1.options.forEach((data: MatOption) => data.deselect());
    this.listLoteMdt = [];
    this.listMDT=[];
    this.dataSourceE = new MatTableDataSource(this.listLoteMdt);
    this.dataSourceE.paginator = this.paginator.toArray()[0];
    this.dataSourceE.sort = this.sort.toArray()[0];

    this.AddCuadrilla.show();
  }

  // Formulario para Agregar cuadrilla
  FormAddCuadrilla = this.fb.group({
    nodo: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    n_cuadrilla: [
      '',
      {
        validators: [Validators.required],
      },
    ],
  });

  // Agregar Cuadrilla
  Enviar: any = {};
  procesarAgregar(): any {
    this.FormAddCuadrilla.markAllAsTouched();


    let list: any = [];

    this.listLoteMdt.forEach((element: any) => {
      let listAdd: any = {};

      listAdd = {
        codigo_nodo: element.codigo_nodo,
        codigo_olt: element.codigo_olt,
        codigo_mdt: element.codigo_mdt,
      };

      list.push(listAdd);
    });

    this.Enviar = {
      n_cuadrilla: this.FormAddCuadrilla.get('n_cuadrilla')?.value,
      user: this.token.getUser(),
      id_cuadrilla: '',
      rel: list,
    };


    // Si el formulario es valido entra y Crea la cuadrilla
    if (this.FormAddCuadrilla.valid == true) {
      this.TecService.CrearCuadrilla(this.Enviar).subscribe({
        next: (data) => {
          this.dataCuadrilla = data;

          // if (response.status == 200) {

          this.Enviar.id_cuadrilla = this.dataCuadrilla.id_cuadrilla;

          // Crear Relacion
          this.TecService.CrearRelacionCuadrilla(this.Enviar).subscribe({
            next: (response_2) => {
              this.CloseModal();

              this.listarCuadrillas();
              this.toast.info(response_2.detalle);
            },
            error: (err) => {
              // En caso de error mostrar el error
              this.content = JSON.parse(err.error).message;
              this.toast.error(this.content);
              this.CloseModal();
            },
          });

          // Crear Relacion de Cuadrilla
          // }
        },
        error: (err) => {
          // En caso de error mostrar el error
          this.content = JSON.parse(err.error).message;
        },
      });
    }
  }

  // Boton Eliminar cuadrilla
  EliminarDict: any = {};
  // Eliminar Cuadrilla
  Eliminar(): void {
    this.TecService.DelCuadrilla(this.EliminarDict).subscribe({
      next: (data) => {
        // alert(JSON.stringify(data))
        //

        // Llevarse el index desde la plantilla, para eliminar sin recargar el div
        // Elimnar del Arreglo
        this.listaDeCuadrillas.splice(this.EliminarDict.index, 1);

        this.listarCuadrillas();
        this.CloseModaleE();

        if (data.statusMensajes == '4') {
          this.toast.error(data.detalle);
        } else {
          this.toast.info(data.detalle);
        }
      },
      error: (err) => {
        // En caso de error mostrar el error
        this.content = JSON.parse(err.error).message;
      },
    });
  }

  //Modal del Tecnico
  openAddTecnico(): any {
    this.AddTecnico.show();
  }

  CloseModalEdit() {
    this.EditCuadrilla.hide();

    this.indmdt = '';

    this.editarModal = {};
    this.listMdtEdt = [];
    this.listarCuadrillas();
  }

  // Funcion para Devolver la lista de Olt o MDT consultando los servicios
  // Se pueden Asignar a una Variable

  ServicesOltmdt(consulta: string, codigo_nodo: any, codigo_olt?: any): any[] {
    let response_oltMdt: any = [];
    // this.response_oltMdt = ""
    if (consulta == 'MDT') {
      this.TecService.getListMDT(codigo_nodo, codigo_olt).subscribe({
        next: (listaMdt) => {
          listaMdt.forEach((element: any) => {
            response_oltMdt.push(element);
          });
        },
        error: (err) => {
          // En caso de error mostrar el error
          // return JSON.parse(err.error).message;
        },
      });
    }

    if (consulta == 'OLT') {
      this.TecService.getListOlt(codigo_nodo.toString()).subscribe({
        next: (listaOlt) => {
          listaOlt.forEach((element: any) => {
            response_oltMdt.push(element);
          });
        },
      });
    }

    return response_oltMdt;
  }

  // Funcion Para el Evento change en OLT
  changeOltEdit(nodo: any, olt: any): any {
    this.TecService.getListMDT(nodo, olt).subscribe({
      next: (listaMdt) => {
        this.ListaMdt = listaMdt;
        //
      },
      error: (err) => {
        // En caso de error mostrar el error
        this.nodosCercano = JSON.parse(err.error).message;
      },
    });
  }

  // Funcion para el Evento Change del Modal Edit
  ObtenerNodosCercanoOltMdt(param1: any): any {
    this.ListaMdt = [];

    this.TecService.getListnodos().subscribe({
      next: (reponse_ndos) => {
        this.nodosCercano = reponse_ndos;
        this.ListaOlt = this.ServicesOltmdt('OLT', param1.toString());

        for (let x in this.nodosCercano) {
          if (this.nodosCercano[x].codigo_nodo == param1.toString()) {
            this.nodosCercano.splice(x, 1);
          }
        }

        // ------------------------------------------
        // Vaciar Variables del Dicccionario
        this.editarModal.codigo_nodo = '';
        this.editarModal.nodo_cercano = '';
        this.editarModal.codigo_mdt = '';
        this.editarModal.codigo_olt = '';


      },
      error: (err) => {
        // En caso de error mostrar el error
        this.nodosCercano = JSON.parse(err.error).message;
      },
    });
  }

  // --------------------------------------------------------
  // Modal Editar Cuadrilla pendiente en realizar
  ListaMdt: any;
  ListaOlt: any;
  listMdtEdt: any = [];
  @ViewChild('mdt2') matRef!: MatSelect;
  // Modal Editar Cuadrilla
  openedit(json: any): void {
    // this.spinner.show();

    this.matRef.options.forEach((data: MatOption) => data.deselect());

    this.indmdt == '';

    // Reset Formulario al abrir el modal
    this.FormEditCuadrilla.reset();

    let data_json: any = {};

    // Datos para Cagar en modal
    // this.editarModal = {
    //   codigo_cuadrilla: json.codigo_cuadrilla,
    //   codigo_nodo: null,
    //   nodo_cercano: null,
    //   nombreCuadrilla: null,
    //   codigo_olt :null,
    //   codigo_mdt :null

    // };zzz
    this.editarModal = [];
    this.editarModal = json;

    this.ObtenerNodos();

    //Limpiar Variables MDT
    this.listMDT = [];
    this.listMdtEdt = this.editarModal.mdtResponse;



    this.dataSource = new MatTableDataSource(this.listMdtEdt);
    this.dataSource.paginator = this.paginator.toArray()[1];
    this.dataSource.sort = this.sort.toArray()[1];


    //this.consultaMDTNew(this.editarModal.codigo_nodo);

    this.nodo1.nativeElement.value = '';
    // this.FormEditCuadrilla.get('nodo')!.setValue('');

    this.EditCuadrilla.show();
    // Consultar el Servicio
    // this.TecService.DetalleCuadrillabyID(parseInt(this.editarModal.codigo_cuadrilla)).subscribe({
    //   next: (data) => {

    //     // alert(JSON.stringify(data))
    //     data_json = data

    //     // Json Editar Modal
    //     this.editarModal.codigo_nodo =  parseInt(data_json.codigo_nodo)
    //     this.editarModal.nodo_cercano =  parseInt(data_json.nodo_cercano)
    //     this.editarModal.nombreCuadrilla =  data_json.nombre_cuadrilla
    //     this.editarModal.codigo_mdt = parseInt(data_json.codigo_mdt)
    //     this.editarModal.codigo_olt = data_json.codigo_olt
    //

    //     this.ListaOlt = this.ServicesOltmdt('OLT',this.editarModal.codigo_nodo)
    //     this.ListaMdt = this.ServicesOltmdt('MDT',this.editarModal.codigo_nodo,this.editarModal.codigo_olt)

    //     // Para Eliminar del nodo cercano el nodo seleccionado
    //     this.ObtenerNodosCercano(this.editarModal.codigo_nodo);

    //     this.ObtenerNodos()

    //     // Pasar
    //     this.NodoPreCargado = parseInt(data_json.codigo_nodo);

    //     //
    //     //  Eliminar el nodo para no seleccionar el mismo
    //     //
    //     //

    //     this.EditCuadrilla.show();

    //   },
    //   error: (err) => {

    //   },
    // });

      // this.spinner.hide();


  }

  // Formulario de Cuadrillas
  FormEditCuadrilla = this.fb.group(
    {
      nodo: [
        '',
        {
          // validators: [Validators.required],
        },
      ],
      NombreCuadrilla: [
        '',
        {
          validators: [Validators.required],
        },
      ],
    },
    { updateOn: 'submit' }
  );

  ListDeletePrev: any = {
    codigo_nodo: null,
    codigo_olt: null,
    codigo_mdt: null,
  };

  ListInsertPrev: any = {
    codigo_nodo: null,
    codigo_olt: null,
    codigo_mdt: null,
  };

  // Procesar Editar la cuadrilla
  ProcesarEditarCuadrilla(pnodo: any, pnombreCuadrilla: any): any {
    // Tocar el Formulario para validar al submit
    this.FormEditCuadrilla.markAllAsTouched();

    // Setear Valores
    this.FormEditCuadrilla.setValue({
      nodo: pnodo,
      NombreCuadrilla: pnombreCuadrilla,
    });

    let ListDelete: any = [];

    let ListInsert: any = [];

    this.rel_eliminar.forEach((element: any) => {
      this.ListDeletePrev = {
        codigo_nodo: null,
        codigo_olt: null,
        codigo_mdt: null,
      };
      this.ListDeletePrev = {
        codigo_nodo: element.codigo_nodo,
        codigo_olt: element.codigo_olt,
        codigo_mdt: element.codigo_mdt,
      };
      ListDelete.push(this.ListDeletePrev);
    });

    this.listMdtEdt.forEach((element: any) => {
      this.ListInsertPrev = {
        codigo_nodo: null,
        codigo_olt: null,
        codigo_mdt: null,
      };
      this.ListInsertPrev = {
        codigo_nodo: element.codigo_nodo,
        codigo_olt: element.codigo_olt,
        codigo_mdt: element.codigo_mdt,
      };
      ListInsert.push(this.ListInsertPrev);
    });




    // Limpiar Json y armar de nuevo
    this.Enviar_Editar = {};
    this.Enviar_Editar = {
      id_cuadrilla: parseInt(this.editarModal.codigo_cuadrilla), //Viene al momento de abrir el modal
      descripcion: this.FormEditCuadrilla.get('NombreCuadrilla')?.value?.trim(),
      rel_eliminar: ListDelete,
      rel_insertar: ListInsert,
    };

    //
    //

    if (this.FormEditCuadrilla.valid == true) {
      this.TecService.editarCuadrillas(this.Enviar_Editar).subscribe({
        next: (data) => {

          this.listarCuadrillas();
          this.CloseModalEdit();
          this.editarModal = {};
          this.NodoPreCargado = '';
          this.toast.info(data.detalle);
          this.rel_eliminar = [];

        },
        error: (err) => {
          // En caso de error mostrar el error
          this.toast.info(err.detalle);
        },
      });
    }
  }

  openeliminar(param: any, index: any) {
    this.eliminar.show();
    this.EliminarDict.id_cuadrilla = param;
    this.EliminarDict.index = index;
  }

  dltMdtLote(idMdt: any) {
    let list: any;

    if (idMdt == '') {
    } else {
      this.listLoteMdt.forEach((element: any) => {
        if (element.id_mdt == idMdt) {
          list = element;
        }
      });

      if (!this.listMDT) {
        let variable = this.listMDT.find(
          (element: { nombre: string }) => element.nombre === list.nombre
        );

        if (!variable) {
          this.listMDT.push(list);
          this.listMDTold = this.listMDT;

          let listMdtNew = this.listLoteMdt.filter(
            (word: any) => word.nombre != list.nombre
          );

          this.listLoteMdt = listMdtNew;
          this.dataSourceE = new MatTableDataSource(this.listLoteMdt);
          this.dataSourceE.paginator = this.paginator.toArray()[0];;
          this.dataSourceE.sort = this.sort.toArray()[0];;
        }
      } else {
        //
        this.listMDT.push(list);
        this.listMDTold = this.listMDT;

        let listMdtNew = this.listLoteMdt.filter(
          (word: any) => word.nombre != list.nombre
        );

        this.listLoteMdt = listMdtNew;

        
        this.dataSourceE = new MatTableDataSource(this.listLoteMdt);
        this.dataSourceE.paginator = this.paginator.toArray()[0];
        this.dataSourceE.sort = this.sort.toArray()[0];

        this.valueBuscar.nativeElement.value = '';
        this.valueMdt.nativeElement.value = '';
      }
    }
  }

  addMdtLote(idMdt: any, codNodo: any) {
    // debugger;
    let list: any;

    if (idMdt == '') {
    } else {
      idMdt.forEach((mdt: Number)  => {
        list = '';
        this.listMDT.forEach((element: any) => {
          if (element.id_mdt == mdt) {
            list = element;
          }
        });

        if (!this.listLoteMdt) {
          let variable = this.listLoteMdt.find(
            (element: { nombre: string }) => element.nombre === list.nombre
          );

          if (!variable) {
            this.listLoteMdt.push(list);
            
            this.dataSourceE = new MatTableDataSource(this.listLoteMdt);
            this.dataSourceE.paginator = this.paginator.toArray()[0];
            this.dataSourceE.sort = this.sort.toArray()[0];
            let listMdtNew = this.listMDT.filter(
              (word: any) => word.nombre != list.nombre
            );

            this.listMDT = listMdtNew;
            this.listMDTold = listMdtNew;
            this.valueBuscar.nativeElement.value = '';
            //this.valueMdt.nativeElement.value = '';

            if (this.listMDT.length == 0) {
              this.indmdt = '';
              this.consultaMDTNew(codNodo);
            }
          }

          //
        } else {
          this.listLoteMdt.push(list);
          
          this.dataSourceE = new MatTableDataSource(this.listLoteMdt);
          this.dataSourceE.paginator = this.paginator.toArray()[0];
          this.dataSourceE.sort = this.sort.toArray()[0];
          let listMdtNew = this.listMDT.filter(
            (word: any) => word.nombre != list.nombre
          );

          this.listMDT = listMdtNew;
          this.listMDTold = listMdtNew;
          this.valueBuscar.nativeElement.value = '';
          // this.valueMdt.nativeElement.value = '';
          if (this.listMDT.length == 0) {
            this.indmdt = '';
            this.consultaMDTNew(codNodo);
          }
        }
      });
    }
  }

  rel_eliminar: any = [];

  dltMdtLote2(idMdt: any) {
    let list: any;

    if (idMdt == '') {
    } else {
      this.listMdtEdt.forEach((element: any) => {
        if (element.id_mdt == idMdt) {
          list = element;
        }
      });

      if (!this.listMDT) {
        let variable = this.listMDT.find(
          (element: { nombre: string }) => element.nombre === list.nombre
        );

        if (!variable) {
          this.listMDT.push(list);
          this.listMDTold = this.listMDT;
          this.rel_eliminar.push(list);

          let listMdtNew = this.listLoteMdt.filter(
            (word: any) => word.nombre != list.nombre
          );

          this.listMdtEdt = listMdtNew;
          this.dataSource = new MatTableDataSource(this.listMdtEdt);
          this.dataSource.paginator = this.paginator.toArray()[1];
          this.dataSource.sort = this.sort.toArray()[1];
        }
      } else {
        //
        this.listMDT.push(list);
        this.listMDTold = this.listMDT;
        this.rel_eliminar.push(list);

        let listMdtNew = this.listMdtEdt.filter(
          (word: any) => word.nombre != list.nombre
        );

        this.listMdtEdt = listMdtNew;
        this.dataSource = new MatTableDataSource(this.listMdtEdt);
        this.dataSource.paginator = this.paginator.toArray()[1];
        this.dataSource.sort = this.sort.toArray()[1];

        //this.valueMdt2.nativeElement.value = '';
      }
    }
  }

  toggleAllSelection() {
    if (this.selectedValues.value && this.isAllSelected()) {
      this.selectedValues.setValue([]);
    } else {
      // let arrayMDT = []
      // this.listMDT.forEach((mdt:any) => {
      //   arrayMDT.push(mdt.id_mdt)
      // });
      this.selectedValues.setValue(this.listMDT.map((item: any) => item.id_mdt));
    }
  }

  isAllSelected() {
    return this.selectedValues.value && this.selectedValues.value.length === this.listMDT.length;
  }

  addMdtLote2(idMdt: any, codNodo: any) {
    let list: any;
    if (idMdt == '') {
    } else {
      idMdt.forEach((mdt: Number)  => {
        list = '';
        this.listMDT.forEach((element: any) => {
          if (element.id_mdt == mdt) {
            list = element;
          }
        });
        if (!this.listMdtEdt) {
          let variable = this.listMdtEdt.find(
            (element: { nombre: string }) => element.nombre === list.nombre
          );

          if (!variable) {
            this.listMdtEdt.push(list);
            
            this.dataSource = new MatTableDataSource(this.listMdtEdt);
            this.dataSource.paginator = this.paginator.toArray()[1];
            this.dataSource.sort = this.sort.toArray()[1];
            let listMdtNew = this.listMDT.filter(
              (word: any) => word.nombre != list.nombre
            );

            this.listMDT = listMdtNew;
            this.listMDTold = listMdtNew;
            this.valueBuscar2.nativeElement.value = '';
            //this.valueMdt2.nativeElement.value = '';
            if (this.listMDT.length == 0) {
              this.indmdt = '';
              this.consultaMDTNew(codNodo);
            }
          }
        } else {
          let mdtResponseAntiPeru = this.editarModal.mdtResponse;
          this.listMdtEdt.push(list);
          this.dataSource = new MatTableDataSource(this.listMdtEdt);
          this.dataSource.paginator = this.paginator.toArray()[1];
          this.dataSource.sort = this.sort.toArray()[1];
          let listMdtNew = this.listMDT.filter(
            (word: any) => word.nombre != list.nombre
          );

          this.listMDT = listMdtNew;
          this.listMDTold = listMdtNew;
          this.valueBuscar2.nativeElement.value = '';
          //this.valueMdt2.nativeElement.value = '';
          if (this.listMDT.length == 0) {
            this.indmdt = '';
            this.consultaMDTNew(codNodo);
          }
        }
      });
    }
  }

  saveeliminar() {
    // confirm or save something
    this.eliminar.hide();
  }

  CloseModaleE(): any {
    this.eliminar.hide();
  }
  CloseModal(): any {
    this.AddCuadrilla.hide();
    this.indmdt == '';
    this.listLoteMdt = [];

    this.valueNodo.nativeElement.value = '';
    this.valueNombre.nativeElement.value = '';

    // confirm or save something
    // this.AddTecnico.hide();

    // Limpiar Variables all salir del modal
    // this.editarModal = '';
    // this.NodoPreCargado = '';
  }

  // ------------------------------------------
  // Obtener OLT
  consultaOlt(obj: any) {
    this.TecService.getListOlt(obj).subscribe({
      next: (data) => {
        this.listOlt = data;
        //
      },
    });
  }

  // ------------------------------------------
  // Obtener MDT
  consultaMdt(olt: any, mdt: any) {
    this.TecService.getListMDT(olt, mdt).subscribe({
      next: (data) => {
        this.listMDT = data;

      },
    });
  }

  indmdt: any = '';
  consultaMDTNew(nodo: any) {
    if (this.indmdt == '') {
      this.indmdt = nodo;
      this.TecService.getListMDTNew(nodo).subscribe({
        next: (data) => {
          this.listMDT = data;
          this.listMDTold = data;

          //Ya no se requiere limpiar la lista mdt
          // this.listLoteMdt = [];

          if (!this.listMdtEdt) {
          } else {
            this.listMdtEdt.forEach((element: any) => {
              let busqueda: any = this.listMDT.filter(
                (work: any) => work.nombre != element.nombre
              );

              this.listMDT = busqueda;
            });
          }
        },
      });
    } else {
      if (nodo != this.indmdt && nodo != '') {
        this.indmdt = nodo;
        this.TecService.getListMDTNew(nodo).subscribe({
          next: (data) => {
            this.listMDT = data;
            this.listMDTold = data;
            // this.listLoteMdt = [];
            if (!this.listMdtEdt) {
            } else {
              this.listMdtEdt.forEach((element: any) => {
                let busqueda: any = this.listMDT.filter(
                  (work: any) => work.nombre != element.nombre
                );

                this.listMDT = busqueda;
              });
            }
          },
        });
      }
    }
  }

  BuscarMdt(parm: any) {
    if (parm == '') {
      this.listMDT = this.listMDTold;
    } else {
      let listMdtNew: any = [];
      let busqueda: any = this.listMDT.filter((element: any) =>
        element.nombre.toUpperCase().includes(parm.toString().toUpperCase())
      );

      busqueda.forEach((element: any) => {
        listMdtNew.push(element);
      });
      this.listMDT = {};
      this.listMDT = listMdtNew;
    }
  }

  // ----------------------------------------------------------------------------------
  // Funcion al Droperar a agregar
  json_crearrela: any;
  AgregarRelacionTec(Tecnico: any, Cuadrillas: any): any {
    this.valor = Cuadrillas;



    // Logica por si acaso
    // let x:any = 0
    // let z:any = 0
    // for (z in this.RecargarLista) {
    //       for (x in this.RecargarLista[z].tecnicosResponse) {
    //          if(this.RecargarLista[z].tecnicosResponse[x].id_tecnico == Tecnico.id_tecnico){
    //
    //          }
    //          x = x +1
    //        }
    //        z = z +1
    //     }

    // Crear Relacion
    this.json_crearrela = {
      id_cuadrilla: parseInt(Cuadrillas.id_cuadrilla),
      id_tecnico: parseInt(Tecnico.id_tecnico),
      id_tipo_tecnico: parseInt(Tecnico.tipo_tecnico),
    };




    this.TecService.CrearRelaCuadrillaTecnico(this.json_crearrela).subscribe({
      next: (response_relacion) => {
        if (response_relacion.statusMensajes != '1') {
          this.toast.error(response_relacion.detalle);
          this.listarTecnicos();
          this.listarCuadrillas();
        } else {
          this.toast.success(response_relacion.detalle);
          this.listarTecnicos();
          this.listarCuadrillas();
        }
      },
      error: (err) => {
        this.toast.error(err.detalle);
      },
    });
  }

  // ----------------------------------------------------------------------------------
  // Funcion al Dropear a Eliminar
  json_destruyeRela: any;
  deleteRelacion(Tecnico: any): any {


    // Crear Relacion
    this.json_destruyeRela = {
      id_cuadrilla: parseInt(Tecnico.idCcuadrilla),
      id_tecnico: parseInt(Tecnico.id_tecnico),
    };



    this.TecService.DelRelaCuadrilla(this.json_destruyeRela).subscribe({
      next: (response_relacion) => {
        // this.listaDeTecnicos()

        if (response_relacion.statusMensajes != '1') {
          this.toast.error(response_relacion.detalle);
          this.listarCuadrillas();
          this.listarTecnicos();
        } else {
          this.toast.success(response_relacion.detalle);
          this.listarCuadrillas();
          this.listarTecnicos();
        }
      },
      error: (err) => {
        this.toast.error(err.detalle);
      },
    });
  }

  // ----------------------------------------------------------------------------------
  // Funcion al Droperar a Actualizar
  json_update: any;
  updateRelacion(Tecnico: any, Cuadrillas: any): any {


    // Crear Relacion
    this.json_update = {
      id_tipo_tecnico: parseInt(Tecnico.tipo_tecnico),
      id_tecnico: parseInt(Tecnico.id_tecnico),
      id_cuadrilla_destino: parseInt(Cuadrillas.codigo_cuadrilla),
      id_cuadrilla_origen: parseInt(Tecnico.idCcuadrilla),
    };



    this.TecService.UpdateCuadrilla(this.json_update).subscribe({
      next: (response_update) => {
        // this.listaDeTecnicos()

        if (response_update.statusMensajes != '1') {
          this.toast.error(response_update.detalle);
          this.listarCuadrillas();
        } else {
          this.toast.success(response_update.detalle);
          this.listarCuadrillas();
        }
      },
      error: (err) => {
        this.toast.error(err.detalle);
      },
    });
  }

  // ----------------------------------------------------------------------------------
  // Eventos del Drop
  // ----------------------------------------------------------------------------------

  //Evento Drag and Drog para lista de cuadrillas
  Drop(event: CdkDragDrop<any[]>, Response: any) {


    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.previousIndex
      );
    } else {
      //Mueve el Array
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,

        event.previousContainer.data[event.currentIndex]
      );

      if (event.container.data[0].idCcuadrilla === undefined) {
        if (
          event.container.data[0].idCcuadrilla === '' ||
          event.container.data[0].codigo_cuadrilla === ''
        ) {
          this.AgregarRelacionTec(event.container.data[0], Response);
        }
      } else {
        this.updateRelacion(event.container.data[0], Response);
      }
    }

    // Actualizar Tecnicos
  }

  // Drop1 para la lista de tecnicos
  Drop1(event: CdkDragDrop<any[]>) {
    // Actualizar Tecnicos

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.previousIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.previousContainer.data[event.currentIndex]
      );

      // Eliminar la Relacion
      this.deleteRelacion(event.container.data[0]);

      this.listarTecnicos();
      //this.listaDeCuadrillas();
    }
  }
}
